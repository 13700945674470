import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import "./index.scss";
import editProdPurchase from "../../../services/edit-prod-purchase";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { defaultOptionsLoading } from "../../../assets/lottie-animations/animationOptions";

interface EditProdPurchaseProps {
  itemName: string;
  unitPrice: number;
  initialQuantity: number;
  onClose: () => void;
  orderId: any;
  product: any;
  doGetPurchaceById: () => void;
}

const EditProdPurchase: React.FC<EditProdPurchaseProps> = ({
  itemName,
  unitPrice,
  initialQuantity,
  onClose,
  orderId,
  product,
  doGetPurchaceById,
}) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = (values: any) => {
    setLoading(true);
    const bodyData = {
      quantity: Number(values.quantity),
      cost: Number(values.unitPrice),
    };
    editProdPurchase(orderId, product?._id, bodyData)
      .then((response) => {
        setLoading(false);

        if (response?.data?.success) {
          toast.success(response?.data?.message);
          onClose();
          doGetPurchaceById();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error);
      });
  };

  return (
    <>
      {loading ? (
        <Lottie options={defaultOptionsLoading} height={"60%"} width={"30%"} />
      ) : (
        <div className="modal-content">
          <Formik
            initialValues={{ quantity: initialQuantity, unitPrice: unitPrice }}
            onSubmit={(values) => {
              console.log(values);

              onConfirm(values);
            }}
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form style={{ height: "100%" }}>
                <div className="header">
                  <h2>Actualisation des informations du produit</h2>
                  <button className="close-button" onClick={onClose}>
                    &times;
                  </button>
                </div>
                <div className="body">
                  <div className="field">
                    <label htmlFor="itemName">Item:</label>
                    <span id="itemName" className="item-name">
                      {itemName}
                    </span>
                  </div>
                  <div className="field-price">
                    <label htmlFor="unitPrice">Prix Unitaire:</label>
                    <div className="input-price-wrapper">
                      <Field
                        id="unitPrice"
                        name="unitPrice"
                        className="unit-price"
                        value={values.unitPrice}
                        onChange={(e : any) => {
                          const value = e.target.value;
                          // Allow only numbers, including decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        type="number"
                      />

                      <span>Dt</span>
                    </div>
                  </div>
                  <div className="field-quantity">
                    <label htmlFor="quantity">Quantité</label>
                    <div className="quantity-control">
                      <button
                        type="button"
                        className="quantity-button"
                        onClick={() =>
                          setFieldValue(
                            "quantity",
                            Math.max(0, values.quantity - 1)
                          )
                        }
                      >
                        &minus;
                      </button>
                      <Field
                        type="number"
                        id="quantity"
                        name="quantity"
                        className="quantity-input"
                        value={values.quantity}
                        readOnly
                      />
                      <button
                        type="button"
                        className="quantity-button"
                        onClick={() =>
                          setFieldValue("quantity", values.quantity + 1)
                        }
                      >
                        &#43;
                      </button>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <button type="submit" className="confirm-button">
                    Confirmer
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EditProdPurchase;
